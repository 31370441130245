/* Loader.css */
.button-loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-top-color: rgba(54,93,148,1);
    animation: spin 0.6s linear infinite;
    display: inline-block;

	
}



/* Variantes de tamaño */
.loader-small {
    width: 16px;
    height: 16px;
    border-width: 2px;
}

.loader-medium {
    width: 32px;
    height: 32px;
    border-width: 4px;
}

.loader-large {
    width: 48px;
    height: 48px;
    border-width: 5px;
}

/* Variantes de estilo */
.loader-secondary {
    border: 3px solid rgba(0, 0, 0, 0.05);
    border-top-color: hsl(215.4 16.3% 56.9%);
}

.loader-ghost {
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.7);
}

/* Animación de rotación */
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Estilos para modo oscuro */
@media (prefers-color-scheme: dark) {
    .loader {
        border-color: rgba(255, 255, 255, 0.1);
        border-top-color: rgba(54,93,148,1);
    }

    .loader-secondary {
        border-color: rgba(255, 255, 255, 0.05);
        border-top-color: rgba(255, 255, 255, 0.7);
    }
}

/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */



html, body, #root, .body {
    height: 100%
}

.loader {
    margin: auto;
    display: block;
    shape-rendering: auto;
}


.row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
  
  .mt-30 {
    margin-top: 30px;
  }

  .ml-10 {
      margin-left: 10px;
  }

  .mr-10 {
      margin-right: 10px;
  }
  
  .error-page-container {
    font-family: 'Barlow Semi Condensed';
    font-size: 20px;
    text-align: center;
    background-color: #ccc;
    height: 100%;
  }
  
  .body {
    background-color: #fff;
  }
  
  @media (min-width: 500px) {
    .body {
      padding: 25px;
      background-color:#ccc;
      background-image: url('./images/bg-pattern-v1.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
  }
  
  .page {
    width: 100%;
    background-color: #EBF5FB;
    background-image: url('https://doqchat.com/static/1860ef6e6b0efb128a6fa37a1c857873.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    margin: 0px auto;
  }
  
  @media (min-width: 500px) {
    .page {
      max-width: 480px;
      border-radius: 5px;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
  }
  
  .section {
    padding: 20px 15px
  }
  
  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 0px;
  }

  .profile-image-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 1px solid #ccc;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    margin-right: 15px;
    position: relative;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50px;
	object-fit: cover;
  }
  
  .profile-image-loader {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;   
	display: flex;
  }

  .name {
    font-family: 'Barlow Semi Condensed';
    font-size: 22px;
    color: #231f20;
    font-weight: 500;
  }
  
  .speciality {
    font-family: 'Barlow Semi Condensed';
    font-size: 18px;
    color: #4d6a93;
    margin-bottom: 6px;
  }

  .about-me-container {
    margin-top: 10px;
    margin-bottom: 20px
  }

  .about-me-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 10px;
    font-family: 'Barlow Semi Condensed';
    font-size: 15px;
  }

  .about-me-title {
    margin-right: 15px;
    font-family: 'Barlow Semi Condensed';
    font-size: 17px;
    color: #333;
    font-weight: 500;
  }

  .social-links-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .social-links-container-bottom {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-top: 1px solid #ccc;
    padding-top: 15px;
  }

  .bio-text {
    font-family: 'Barlow Semi Condensed';
    font-size: 16px;
    color: #333;
    line-height: 23px;
  }
  
  .code {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .code-line {
    background: #c4cad6;
    height: 1px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  
  .bottom-line {
    background: #c4cad6;
    height: 1px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-right: 10px;
    max-width: 300px;
      margin: 26px auto;
  }
  
  .code-value {
    background-color: rgba(54,93,148,1);
    -webkit-box-shadow: 1px 1px 1px #333;
            box-shadow: 1px 1px 1px #333;
    border: 1px solid #7c7c7c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 15px;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-family: 'Barlow Semi Condensed';
    font-weight: 500;
    border-radius: 4px;
    font-size: 16px;
    line-height: 23px;
    color: #fff
  }
  
  .doqchat-logo-container {
    text-align: center;
    margin-top: 25px;
  }
  
  .doqchat-logo {
    height: 80px;
  }
  
  .desktop .bottom-legend {
    font-family: 'Barlow Semi Condensed';
    font-size: 15px;
    line-height: 23px;
    color: #231f20;
    text-align: center;
    margin: 20px 10px;
  }
  
  .mobile .bottom-legend {
    font-family: 'Barlow Semi Condensed';
    font-size: 16px;
    line-height: 23px;
    color: #231f20;
    text-align: center;
    max-width: 360px;
    margin: 40px auto 0px;
  }
  
  .desktop .bottom-container {
    margin: 30px auto 15px;
    padding: 10px;
    text-align: center;
  }
  
  .mobile .bottom-container {
      margin: 30px auto 15px;
      text-align: center;
  }
  
  .desktop .button-secondary {
	background: rgb(255, 255, 255);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#e95855",GradientType=1);
    border: none;
    padding: 10px 30px;
    color: rgba(2, 0, 36, 0.607);
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Barlow Semi Condensed';
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    outline: none;
    cursor: pointer;
  }

  .mobile .button-secondary {
    background: rgb(255, 255, 255);
    border: none;
    padding: 15px 30px;
    color: rgba(54,93,148,1);
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Barlow Semi Condensed';
    outline: none;
    cursor: pointer;
    text-align: center;
    max-width: 200px;
    display: block;
    margin: 0px auto;
  }
  
  
  .desktop .button {
    background: rgb(2,0,36);
    background: -o-linear-gradient(left, rgba(2,0,36,1) 0%, rgba(54,93,148,1) 0%, rgba(233,88,85,1) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(2,0,36,1)), color-stop(0%, rgba(54,93,148,1)), to(rgba(233,88,85,1)));
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(54,93,148,1) 0%, rgba(233,88,85,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#e95855",GradientType=1);
    border: none;
    padding: 10px 30px;
    color: #fff;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Barlow Semi Condensed';
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    outline: none;
    cursor: pointer;
  }
  
  .mobile .button {
    background: rgb(2,0,36);
    background: -o-linear-gradient(left, rgba(2,0,36,1) 0%, rgba(54,93,148,1) 0%, rgba(233,88,85,1) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(2,0,36,1)), color-stop(0%, rgba(54,93,148,1)), to(rgba(233,88,85,1)));
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(54,93,148,1) 0%, rgba(233,88,85,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#e95855",GradientType=1);
    border: none;
    padding: 10px 30px;
    color: #fff;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Barlow Semi Condensed';
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.21);
    outline: none;
    cursor: pointer;
    text-align: center;
    max-width: 200px;
    display: block;
    margin: 0px auto;
  }
  
  
  .desktop .qr {
    width: 180px;
    height: 180px;
    border: 1px solid #ccc;
  }


  /* Social networks */

  .social-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .social-link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10px;
  }

  .facebook-background {
    background-color: #3B5998;
    text-align: center;
    padding: 8px;
    border-radius: 3px;
    width: 20px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }


  .instagram-background {
    background: #d6249f;
    background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    text-align: center;
    padding: 8px;
    border-radius: 3px;
    width: 20px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  .web-background {
    background: #3498DB;
    text-align: center;
    padding: 8px;
    border-radius: 3px;
    width: 20px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  .facebook-background:hover, .instagram-background:hover, .web-background:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
  }

  .read-more-content {
      color: 'orange';
      background-color: red;
  }


/* Popup */
.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	animation: fadeIn 0.3s ease-out;
  }
  
  .popup-container {
	background-color: white;
	border-radius: 8px;
	width: 90%;
	max-width: 500px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	overflow: hidden;
	animation: slideIn 0.3s ease-out;
  }
  
  .popup-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 20px;
	border-bottom: 1px solid #f1f1f1;
  }
  
  .popup-header h3 {
	margin: 0;
	font-family: 'Barlow Semi Condensed', sans-serif;
	font-size: 1.3rem;
	color: #333;
  }
  
  .popup-close-button {
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
	color: #888;
	padding: 0;
	line-height: 1;
  }
  
  .popup-close-button:hover {
	color: #333;
  }
  
  .popup-content {
	padding: 15px 20px;
	font-family: 'Barlow Semi Condensed', sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
  }
  
  .popup-content p {
	margin-bottom: 10px;
	line-height: 1.5;
	color: #555;
  }
  
  .popup-footer {
	display: flex;
	justify-content: flex-end;
	padding: 15px 20px;
	border-top: 1px solid #f1f1f1;
	gap: 10px;
  }
  
  .popup-button {
	padding: 10px 15px;
	border-radius: 4px;
	font-family: 'Barlow Semi Condensed', sans-serif;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
	border: none;
  }
  
  .popup-button-primary {
	background-color: #2a9af1;
	color: white;
  }
  
  .popup-button-primary:hover {
	background-color: #1c8be0;
  }
  
  .popup-button-secondary {
	background-color: #f1f1f1;
	color: #333;
  }
  
  .popup-button-secondary:hover {
	background-color: #e1e1e1;
  }
  
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  @keyframes slideIn {
	from {
	  transform: translateY(-20px);
	  opacity: 0;
	}
	to {
	  transform: translateY(0);
	  opacity: 1;
	}
  }
  
  @media (max-width: 480px) {
	.popup-container {
	  width: 95%;
	}
	
	.popup-header h3 {
	  font-size: 1.2rem;
	}
	
	.popup-footer {
	  flex-direction: column;
	}
	
	.popup-button {
	  width: 100%;
	}
  }

.init-consultation-tg{
	display: flex;
	flex-direction: row;
	gap:8px;
	align-items: center;
}
.init-consultation-tg button:disabled{
	opacity: 0.5;
}

.init-consultation-tg img{
	padding-top: 5px;
}